import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CLabelCard,
  CustomLink,
  CContactBox,
  CFocalCard,
  AssetImage,
  CBtnList,
  CSectTitle02,
} from '../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            en: (
              <>
                banquet/ <br className="u_sp" />
                meeting
              </>
            ),
            ja: '宴会・会議',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: '宴会・会議',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect">
        <section className="u_mbExLarge">
          <LWrap>
            <h2 className="c_headingLv2">
              有意義な時間を創る
              <br />
              開放的な環境と選べる会場
            </h2>
            <p className="c_sectLead">
              杜の都に相応しい自然溢れるリゾート環境と幅広いご要望に対応できる
              <br />
              大小さまざまな会場が親睦を深める宴会や生産性の高い会議の後押しをします。
            </p>
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: 'プラン',
                en: <>PLAN</>,
              }}
            />
            <CFocalCard
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/img_menu.png',
                    alt: '宴会・パーティー',
                  },
                  title: {
                    en: <></>,
                    ja: <>宴会・パーティー</>,
                  },
                  link: {
                    href: '/banquet/party/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_menu02.png',
                    alt: '会議・講演・セミナー',
                  },
                  title: {
                    en: <></>,
                    ja: <>会議・講演・セミナー</>,
                  },
                  link: {
                    href: '/banquet/meeting/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_menu03.png',
                    alt: 'イベント・展示',
                  },
                  title: {
                    en: <></>,
                    ja: <>イベント・展示</>,
                  },
                  link: {
                    href: '/banquet/event/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_menu04.png',
                    alt: 'インセンティブ',
                  },
                  title: {
                    en: <></>,
                    ja: <>インセンティブ</>,
                  },
                  link: {
                    href: '/banquet/incentive/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_menu05.png',
                    alt: 'ケータリング',
                  },
                  title: {
                    en: <></>,
                    ja: <>ケータリング</>,
                  },
                  link: {
                    href: '/banquet/catering/',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: '宴会場',
                en: <>BANQUET</>,
              }}
            />
            <CLabelCard
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet.png',
                    alt: 'ロイヤルホール',
                  },
                  label: '大宴会場',
                  title: <>ロイヤルホール</>,
                  text: <>ロビーフロア</>,
                  link: {
                    href: '/banquet/grand_hall/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet02.png',
                    alt: 'クラウンルーム',
                  },
                  label: '中宴会場',
                  title: <>クラウンルーム</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/crown/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet03.png',
                    alt: 'モンシェリー',
                  },
                  label: '中宴会場',
                  title: <>モンシェリー</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/mon_cheri/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet04.png',
                    alt: 'エクスクルーシブテーブル',
                  },
                  label: '小宴会場',
                  title: <>エクスクルーシブテーブル</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/exclusive_table/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet05.png',
                    alt: 'うたげ',
                  },
                  label: '小宴会場',
                  title: <>うたげ</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/utage/',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet06.png',
                    alt: 'かすみ',
                  },
                  label: '小宴会場',
                  title: <>かすみ</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/utage#kasumi',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_banquet07.png',
                    alt: 'あや',
                  },
                  label: '小宴会場',
                  title: <>あや</>,
                  text: <>ガーデンフロア</>,
                  link: {
                    href: '/banquet/utage#aya',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: 'その他',
                en: <>OTHER</>,
              }}
              exClass="u_mbLargeMedium"
            />
            <div className="l_other">
              <CBtnList
                data={[
                  {
                    label: 'オプション',
                    link: {
                      href: '/assets/files/pdf/banquet_option.pdf',
                      blank: true,
                    },
                    icon: 'blank',
                    color: 'borderTheme',
                  },
                ]}
              />
              <CBtnList
                data={[
                  {
                    label: 'ファクトシート',
                    link: {
                      href: '/assets/files/pdf/banquet_factsheet.pdf',
                      blank: true,
                    },
                    icon: 'blank',
                    color: 'borderTheme',
                  },
                ]}
              />
            </div>
          </LWrap>
        </section>
      </div>
      <section className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle
            title={{
              ja: 'お問合せ',
              en: <>CONTACT</>,
            }}
          />
          <p className="c_sectLead u_mb30">
            条件や予算をお伺いし、最適なプランをご提案させていただきます。
          </p>
          <CContactBox
            color="white"
            tel={{
              number: '022-377-2100',
              note: <>＜受付時間＞ 平日　10:00～17:00</>,
            }}
            mail={{
              link: {
                href: 'https://contact.royalparkhotels.co.jp/srph/contact',
              },
            }}
          />
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
